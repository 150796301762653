import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { ApolloError, useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import { fetchCities, fetchInitialCity } from '../../api/public/city';
import { selectCity, setCity } from '../../store/mainSlice';
import { useDispatch, useSelector } from '../../store/hooks';
import { Modal, TextField, ErrorAlert } from '../../components';
import { getToken } from '../../store/loginSlice';
import { UPDATE_CITY_MUTATION } from '../../api/public/cabinet/profile';
import { useDebouncedState } from '../../hooks';
import { findBotByUserAgent } from '../../features/string';

import styles from './CitySelect.module.scss';
import { City } from '../../__generated__/graphql';

export const CitySelect = () => {
  const city = useSelector(selectCity);
  const token = useSelector(getToken);
  const dispatch = useDispatch();

  const [showSelect, setShowSelect] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [cities, setCities] = useState<City[]>([]);
  const [error, setError] = useState<ApolloError | undefined>();

  const query = useDebouncedState(search, 500);

  const [updateCity, { error: cityUpdateError }] = useMutation(UPDATE_CITY_MUTATION, {
    onError: (err) => setError(err),
  });

  useEffect(() => {
    if (showSelect) {
      fetchCities(query)
        .then((resp) => {
          // FIXME
          // @ts-ignore
          setCities(resp);
          setError(undefined);
        })
        .catch((err) => {
          setError(err);
        });
    }
  }, [query, showSelect]);

  useEffect(() => {
    const isBot = findBotByUserAgent(window.navigator.userAgent);

    if (!city) {
      fetchCities()
        .then((resp) => {
          // FIXME
          // @ts-ignore
          setCities(resp);
          setError(undefined);
          if (!isBot) {
            fetchInitialCity()
              .then((city) => {
                dispatch(setCity(city || resp[0]));
              })
              .catch(() => {
                // FIXME
                // @ts-ignore
                dispatch(setCity(resp[0]));
              });
          } else {
            // FIXME
            // @ts-ignore
            dispatch(setCity(resp[0]));
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setShowSelect(true);
  };

  const changeCity = (city: City) => {
    if (token) {
      updateCity({
        variables: {
          cityId: city.id,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
    setSearch('');
    dispatch(setCity(city));
    setShowSelect(false);
  };

  return (
    <>
      <a onClick={handleClick} href="">
        <Image src="/svg/place.svg" width={20} height={20} alt="" />
        <span className={styles.linkText}>{city ? city.name : ''}</span>
      </a>
      <Modal
        show={showSelect}
        onClose={() => setShowSelect(false)}
        title="Ваш город"
        containerClassName={styles.modal}
      >
        <>
          {isMobile && (
            <p className={styles.currentCity}>{city ? city.name : ''}</p>
          )}
          <TextField
            name="text"
            value={search}
            onChange={(e: React.ChangeEvent<any>) => {
              setSearch(e.target.value);
            }}
          />
          <div className={styles.selectCityList}>
            {cities.slice(0, 9).map((c) => (
              <div key={c.id} className={styles.item}>
                <button onClick={() => changeCity(c)}>{`${c.name} (${c?.region?.name})`}</button>
              </div>
            ))}
          </div>
          <ErrorAlert
            error={error || cityUpdateError}
            mapMessage={{
              'Failed to fetch': 'Отсутсвует соединение с сервером',
            }}
          />
        </>
      </Modal>
    </>
  );
};

export default CitySelect;
