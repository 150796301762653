import clsx from 'clsx';
import React, { useState } from 'react';
import axios from 'axios';
import { formatDateMonth } from '../../features/date';
import { formatPrice } from '../../features/string';
import CabinetContentLayout from '../CabinetContentLayout/CabinetContentLayout';
import { useSelector } from '../../store/hooks';
import { getToken } from '../../store/loginSlice';
import DownloadButton from '../DownloadButton/DownloadButton';
import { Button } from '../Button/Button';

import styles from './OrderDetails.module.scss';
import { UPDATE_ORDER } from '../../api/public/order';
import { useMutation } from '@apollo/client';
import { CANCEL_ORDER } from '../../api/public/cabinet/orders';
import { Modal } from '../Modal/Modal';
import Link from 'next/link';
import { OrderItems } from './components/OrderItems';
import OrderStatusChip from '../OrderStatus/OrderStatusСhip';
import { OrderItemStatus, OrderQuery, OrderStatus } from '../../__generated__/graphql';
import HelpMailOrder from './components/HelpMailOrder';

type Props = {
  order: OrderQuery['order'];
  refetch: () => void
}

export const OrderDetails = ({ order, refetch } : Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const token = useSelector(getToken);

  const exportRequest = () => {
    return axios.get(`${process.env.NEXT_PUBLIC_API_URL}/order/bill?order_id=${order.id}`, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const actions = (
    <Button title={'Задать вопрос по заказу'} onClick={() => setShowMailModal(true)} variant="default" size="small" />
  );

  const isAllOrderItemsChecked = order.items.find((item) => item.orderItem.status === 'DRAFT' || item.orderItem.status === 'ORDERED') ? false : true;

  const [updateOrderItem] = useMutation(UPDATE_ORDER, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetch();
    }
  });

  const [cancelOrder, { loading }] = useMutation(CANCEL_ORDER, {
    variables: {
      orderId: order.id,
    },
    onCompleted: () => {
      refetch();
      setShowConfirm(false);
    },
    onError: (err) => console.error(err),
  });

  const showBill = () => {
    const isAllItemsResolved = order.items.every((item) => item.orderItem.status !== OrderItemStatus.Changed);
    
    return (
      <div className={styles.billDownloadContainer}>
        {typeof order.delivery_summ === 'number' 
        ? (
          <DownloadButton
            size="medium"
            apiDefinition={exportRequest}
            title={'Загрузить счёт'}
            disabled={!isAllItemsResolved}
          />
        ) 
        : <span className={styles.billWaitHelp}>Счёт будет доступен после уточнения цен и расчёта доставки</span>}
      </div>
    );
  };

  return (
    <CabinetContentLayout
      backLink="/cabinet/orders"
      header={`Заказ №${order.num}`}
      actions={actions}
    >
      <div className={styles.infoContainer}>
        <div className={styles.infoTable}>
          <div className={clsx(styles.item, styles.infoItem)}>
            <span className={styles.header}>Дата заказа</span>
            <span>{formatDateMonth(new Date(order.created_at))}</span>
          </div>
          <div className={clsx(styles.item, styles.infoItem)}>
            <span className={styles.header}>Общий статус</span>
            <OrderStatusChip status={order.status} />
          </div>
          {order.request && 
            <div className={clsx(styles.item, styles.infoItem)}>
              <span className={styles.header}>Заявка</span>
              <Link href={`/cabinet/requests/${order.request.id}`}>{order.request.num}</Link>
            </div>
          }
          <div className={styles.addressItem}>
            <span className={styles.header}>Адрес доставки</span>
            <span>{order.delivery_address || 'Не указан'}</span>
          </div>
          <div className={clsx(styles.item, styles.infoItem)}>
            <span className={styles.header}>Стоимость товара</span>
            <span>{formatPrice(order.summ)}</span>
          </div>
          <div className={clsx(styles.item, styles.infoItem)}>
            <span className={styles.header}>Стоимость доставки</span>
            <span>
              {order.delivery_summ
                ? formatPrice(order.delivery_summ)
                : 'Рассчитывается'}
            </span>
          </div>
        </div>
        <div style={{margin: '25px 0 15px 0', display:'flex', gap: '15px', flexDirection:'column', alignItems:'flex-start'}}>
          <h2 style={{margin: 0, fontSize: '1.4em'}}>Состав заказа</h2>
        </div>
        <div className={clsx(styles.productsTable, isAllOrderItemsChecked && styles.allChecked)}>
          <div className={styles.item}>
            <span className={styles.header}>№</span>
          </div>
          <div className={styles.item}>
            <span className={styles.header}>Наименование</span>
          </div>
          <div className={styles.item}>
            <span className={styles.header}>Цена</span>
          </div>
          <div className={styles.item}>
            <span className={styles.header}>Наличие</span>
          </div>
          <div className={styles.item}>
            <span className={styles.header}>Склад</span>
          </div>
          {isAllOrderItemsChecked && 
            <div className={styles.item}>
              <span className={styles.header}>Статус</span>
            </div>
          }
          <div className={clsx(styles.item, styles.amountItem)}>
            <span className={styles.header}>Кол-во</span>
          </div>
          <div className={clsx(styles.item, styles.amountItem)}>
            <span className={styles.header}>Итого</span>
          </div>
          <OrderItems orderItems={order.items} updateOrderItem={updateOrderItem} isAllOrderItemsChecked={isAllOrderItemsChecked} />
        </div>
      </div>
      <div className={styles.bottomActions}>
        {((order.status === OrderStatus.New) || (order.status === OrderStatus.Confirmed)) ? 
          <Button onClick={() => setShowConfirm(true)} textColor='danger' title='Отменить заказ' size='small' />
        : <div></div>}
        {order.status !== OrderStatus.Canceled && 
          <div style={{maxWidth: '300px'}} className={styles.infoItem}>
            <span className={styles.header}>Счет</span>
            {showBill()}
          </div>
        }
      </div>
      <Modal show={showMailModal} onClose={() => setShowMailModal(false)}>
        <HelpMailOrder orderId={order.id} />
      </Modal>
      <Modal onClose={() => setShowConfirm(false)} show={showConfirm}>
        <div className={styles.confirmModal}>
          <h4>Вы уверены что хотите отменить заказ?</h4>
          <div className={styles.confirmationButtonsBox}>
            <Button title='Отмена' variant='primary' onClick={() => setShowConfirm(false)} />
            <Button title='Удалить' textColor='danger' loading={loading} onClick={cancelOrder} />
          </div>
        </div>
      </Modal>
    </CabinetContentLayout>
  );
};

export default OrderDetails;
